import request from "utils/request";

export default {
  complete: request("post", "/api/v1/kiosk-payment/complete"), //키오스크 - 결제 승인
  payment: request("post", "/api/v1/kiosk-payment"), //키오스크 - 결제
  festival: request("get", "/api/v1/kiosk/branch"), //키오스크에 등록된 페스티벌 보기
  getItems: request("get", "/api/menus"), //상품목록 조회
  getDetail: request("get", "/api/menus/:id"), //메뉴 상세정보 보기
  addNormal: request("post", "/api/v2/carts/normal"), //키오스크 (normal)주문 상품보기
  addTimeMain: request("post", "/api/v2/carts/time/main"), //키오스크 (normal)주문 상품보기
  addTimeType: request("post", "/api/v2/carts/time/type"), //키오스크 (normal)주문 상품보기
  getMenuDates: request("get", "/api/menus/:id/menutimes/date"), //시간 상품별 날짜 목록
  getMenuTimes: request("get", "/api/menus/:id/menutimes"), //시간 상품 시간별 수량 목록
  getCart: request("get", "/api/v2/carts"), //시간 상품별 날짜 목록
  removeOne: request("delete", "/api/v2/carts/:id"), //키오스크 단일 주문 삭제
  removeAll: request("delete", "/api/v2/carts/kiosk-all"), //키오스크 주문 전체 삭제
  fastestSingle: request(
    "get",
    "/api/v1/carts/first-order-menus/time/main/kiosk"
  ), //키오스크 주문 전체 삭제
  fastestType: request(
    "get",
    "/api/v1/carts/first-order-menus/time/type/kiosk"
  ), //키오스크 주문 전체 삭제
  updateCount: request("post", "/api/v2/carts/update-count/:id"), //키오스크 장바구니 상품 수량 증감
  // getFestival: request("get", "/api/v1/kiosk/branch"), //키오스크에 등록된 페스티벌 조회
  getCategory: request("get", "/api/v1/kiosk/:id/businesses/categories"), //카테고리 목록 조회
  ad: request("get", "/api/v1/ad/:id", { uselessAuth: true }),

  getTerm: request("get", "/api/v1/documents,",{ uselessAuth: true }), // 키오스크 약관 조회
};
