import { useState, useEffect } from "react";

// components
import Cart from "layout/cart";
import { Grid, Container, Box, Typography } from "@mui/material";
import { ItemCard, ItemArrow, Loading } from "components/unitComponent";
import { useNavigate } from "react-router-dom";

// translations

import { useTranslation } from "react-i18next";

import Slider from "react-slick";
import Api from "utils/wApi/index";
import useCart from "../store";
import AdMenu from "../components/ad/ad-menu";

// ----------------------------------------------------------------------

export default function Items() {
	const { t } = useTranslation();
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showCount, setShowCount] = useState(9);
	const [page, setPage] = useState([]);

	const navigate = useNavigate();

	const { active, cart } = useCart((state) => state);

	const itemSliderSetting = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <ItemArrow />,
		prevArrow: <ItemArrow prev />,
	};

	async function init() {
		setLoading(true);

		try {
			const {
				data: { data },
			} = await Api.getItems(null, null, {
				params: { size: 100, idBranchMenu: active },
			});
			setItems(data.content);

			// 광고 유무에 따라 슬라이드 갯수 9개 or 6개로 세팅
			try {
				const adData = await Api.ad(null, "ad_4", {});

				setShowCount(adData ? 6 : 9);
			} finally {
				setPage([
					...Array.from(
						{ length: Math.ceil(data.content.length / showCount) },
						(v, i) => i + 1
					),
				]);
			}
		} catch (error) {
			console.log("menus error :: ", error);
		}

		setLoading(false);
	}
	useEffect(() => {
		if (!active) return navigate("/");
		init();
	}, [active]);

	return (
		<Cart>
			<Container
				sx={{
					width: "100%",
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
					"padding-left": "0px !important",
					"padding-right": "0px !important",
					"&:-webkit-scrollbar": { display: "none" },
					height: 812,
					overflow: "hidden",
				}}
			>
				{loading ? (
					<Loading sx={{ height: "500px", width: "100%" }} />
				) : (
					<>
						<Box
							sx={{
								"&:-webkit-scrollbar": { display: "none" },
								pt: 3,
								position: "relative",
								flexGrow: 1,
								".slick-dots": {
									bottom: "-33px",
									"li button:before": { fontSize: 12 },
								},
								"li.slick-active": {
									button: {
										"::before": { color: "primary.main" },
										":b": { width: "30px" },
									},
								},
							}}
						>
							<Slider {...itemSliderSetting}>
								{page.map((page, i) => (
										<Grid
											container
											rowSpacing={1.5}
											key={i}
										sx={{ px: 8, display: "flex !important", rowGap: "10px", columnGap: '20px', m: 0}}
										>
											{items
												.slice(i * showCount, i * showCount + showCount)
												.map((item, idx) => (
														<ItemCard
															item={item}
															sx={{py:0,width:205}}
															onClick={() => {
																navigate(`/detail/${item.id}`);
															}}
														/>
												))}
										</Grid>
									)
								)}
							</Slider>
						</Box>

						<AdMenu />
					</>
				)}
			</Container>
		</Cart>
	);
}
