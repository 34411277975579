import { Box, Container } from "@mui/material";

import Header from "layout/header/index";

// ----------------------------------------------------------------------

export default function Layout({ children, sx }) {
	

	return (
		<Box
			bgcolor="grey.100"
			sx={{
				height: "100vh",
				minHeight: "100vh",
				maxHeight: "100vh",
				overflow: "hidden",
				"&:-webkit-scrollbar": { display: "none !important" },
			}}
		>
			<Container
				sx={{
					backgroundColor: "white",
					height: "100%",
					px: `0 !important`,
					display: "flex",
					flexDirection: "column",
					"&:-webkit-scrollbar": { display: "none !important" },
				}}
			>
				<Header />
				<Box
					sx={{
						flexGrow: 1,
						maxHeight: "calc(100vh - 95px)",
						"&:-webkit-scrollbar": { display: "none !important" },
						// overflow: "scroll",
						...sx,
					}}
				>
					{children}
				</Box>
			
			</Container>
		</Box>
	);
}
