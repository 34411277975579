import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { backdropClasses, Box } from "@mui/material";
import SvgIconStyle from "./SvgIconStyle";
import LogoIcon from "assets/icons/logo.svg";

// ----------------------------------------------------------------------

function Logo({ onDark = false, isSimple = false, sx }) {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				lineHeight: 0,
				display: "inline-flex",
				...sx,
			}}
			// onClick={
			// 	() => navigate(`/?code=${localStorage.getItem("Kiosk_ID")}`)
			// }
		>
			<SvgIconStyle
				src={LogoIcon}
				origin
				sx={{ width: 128, minWidth: 128, maxWidth: 128 }}
			/>
		</Box>
	);
}

export default memo(Logo);
